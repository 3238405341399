.SavedEntityListRoot {
  overflow: auto;
  width: 100%;
  padding: 0.5rem;

  @media screen and (max-width: 40em) {
    min-height: 220px;
  }
}

.SavedEntityListItem {
  .SavedEntityListItemIcon:last-child {
    justify-self: start;
  }
}

.LoadingWrapper {
  min-height: unset;
  height: 100%;
}
